<template>
  <div class="serviceDetails w">
    <div class="bids_desc_top_big_box">
      <div class="bids_desc_top_info">
        <div class="name_and_price_box">
          <div class="bids_desc_name">{{detailsData.name}}</div>
        </div>
        <div class="bids_area_box">
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">采购类型：</div>
            <div class="bids_ep_row_text">{{ detailsData.category_string }}</div>
          </div>
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">地点：</div>
            <div class="bids_ep_row_text">{{detailsData.area}}</div>
          </div>
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">发布时间：</div>
            <div class="bids_ep_row_text">{{detailsData.create_time}}</div>
          </div>
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">截止时间：</div>
            <div class="bids_ep_row_text">{{detailsData.end_date}}</div>
          </div>
        </div>
      </div>
      <div class="top_right_btn_box">
        <div class="phone_or_send">
          <button class="send_price_btn" @click="sendNowPrice">
            立即报价
          </button>
          <button class="s_blue_btn" @click="goLink('/personalCenter/member')" v-if="!$store.state.userInfo.vip.vip_level">开通会员查看电话</button>
          <button class="s_blue_btn" @click="callPhone"
            v-if="$store.state.userInfo.vip.vip_level&&$store.state.userInfo.vip.vip_level!=0">电话联系</button>
        </div>
        <div class="collect_btn" @click="collectFunc">
          <img src="../../assets/img/icon/icon_active_collect.png" alt="" v-if="detailsData.if_collect">
          <img src="../../assets/img/icon/icon_collect.png" alt="" v-else>
          <span>收藏</span>
        </div>
      </div>
    </div>

    <div class="desc_content">
      <!-- 摘要盒子 -->
      <div class="area_box">
        <div class="area-title">采购企业</div>
        <div class="company_box">
          <div class="company_info_box">
            <div class="company_img_box">
              <img :src="detailsData.image" alt="">
            </div>
            <div class="company_name">
              {{ detailsData.company_name}}
            </div>
          </div>
          <div class="check_company_btn">
            <button class="s_blue_btn" @click="goEpDeatils">查看企业</button>
          </div>
        </div>
      </div>
      <!-- 附件盒子 -->
      <div class="area_box min-h-285">
        <div class="area-title">附件</div>
        <div class="files_list_box" v-if="auth=='pass'">
          <div class="files_item" v-for="(item,index) in detailsData.images_view" :key="index">
            <img :src="item" />
          </div>
        </div>
        <smallOpenVip v-if="auth!=='pass'"></smallOpenVip>
      </div>
      <!-- 详情盒子 -->
      <div class="area_box bids_details_box">
        <div class="area-title">采购要求</div>
        <div class="inner_desc_box" v-if="detailsData.desc">
          <p v-html="formattedString"></p>
        </div>
        <div class="inner_desc_box" v-else>
          ****************************************************************************
        </div>
        <bigOpenVipVue v-if="auth!=='pass'"></bigOpenVipVue>
      </div>
    </div>
    <el-dialog :visible.sync="sendPriceShow" title="填写报价信息" width="50%" :before-close="closeSendPriceShow">
      <div class="sendPrice_model">
        <el-form :model="sumitObj" :rules="rules" ref="sumitObj" label-width="100px" class="demo-sumitObj">

          <div class="page_area_title">
            <span class="must_point">*</span> 联系人信息
          </div>
          <el-form-item label="联系人:" prop="contact">
            <el-input v-model="sumitObj.contact" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contact_phone">
            <el-input v-model="sumitObj.contact_phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <div class="page_area_title">
            <span class="must_point">*</span> 上传报价单
          </div>
          <div class="upload_img_box">
            <el-form-item prop="images">
              <uploadImageList ref="uploadImageList" @success="uploadSuccess">
              </uploadImageList>
            </el-form-item>
          </div>
          <div class="page_area_title">
            <span class="must_point">*</span> 报价描述
          </div>
          <div class="text_area_box">
            <el-form-item prop="desc">
              <el-input type="textarea" v-model="sumitObj.desc" placeholder="请详细说明报价信息"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSendPriceShow">取 消</el-button>
        <el-button type="primary" @click="comfirmSendPrice">确定报价</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bigOpenVipVue from '../../components/vipPopup/bigOpenVip.vue';
import smallOpenVip from '../../components/vipPopup/smallOpenVip.vue';
import uploadImageList from '@/components/uploadImage/uploadImageList.vue';

export default {
  components: {
    smallOpenVip,
    bigOpenVipVue,
    uploadImageList
  },
  data () {
    return {
      vipShow: false,
      approveShow: false,
      sendPriceShow: false,
      id: '',
      auth: '',
      pageAuth: 'package_phone',
      detailsData: {},
      sumitObj: {
        purchase_id: '',
        company_id: '',
        contact: '',
        contact_phone: '',
        desc: '',
        images: []
      },
      rules: {
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        images: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请输入采购信息', trigger: 'blur' },
        ],
      },
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    closeVipShow () {
      this.vipShow = false;
    },
    uploadSuccess (val) {
      this.$set(this.sumitObj, 'images', val);
    },
    closeSendPriceShow () {
      this.sendPriceShow = false;
      this.$refs.uploadImageList.clearImageList();
      this.sumitObj = {
        purchase_id: '',
        company_id: '',
        contact: '',
        contact_phone: '',
        desc: '',
        images: []
      }
    },
    //打开报价框
    sendNowPrice () {
      this.checkMemberDealAuth('collection_price').then(res => {
        if (res.code == 1) {
          this.sendPriceShow = true;
        }
      });
    },
    //确认发送报价
    comfirmSendPrice () {
      this.$refs.sumitObj.validate((valid) => {
        if (valid) {
          let params = {
            ...this.sumitObj
          }
          params.images += '';
          params.company_id = this.detailsData.company_id;
          params.purchase_id = this.detailsData.id;
          this.$api.sendPrice(params).then(res => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.closeSendPriceShow();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closeApproveShow () {
      this.approveShow = false;
    },
    collectFunc () {
      this.$api.collect({ id: this.id, type: 'purchase' }).then(res => {
        if (res.code == 1) {
          if (res.data.if_collect == 0) {
            this.$message.success('已取消收藏');
          } else if (res.data.if_collect == 1) {
            this.$message.success('收藏成功!');
          }
          this.detailsData.if_collect = res.data.if_collect;
        }
      });
    },
    getDetails () {
      this.$api.getPurchaseDetail({ id: this.id }).then(res => {
        this.detailsData = res.data;
        this.auth = 'pass';
      });
    },
    //前往企业详情页
    goEpDeatils () {
      this.$router.push({
        path: '/ep-details',
        query: {
          id: this.detailsData.company_id,
        }
      })
    },
    callPhone () {
      this.$api.getPurchasePhone({ id: this.id }).then(res => {
        if (res.code == 1) {
          if (res.data.contact_phone) {
            alert(`
                联系方式:${res.data.contact_phone}
          `)
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  computed: {
    formattedString () {
      // 使用正则表达式将换行符替换为<br>标签
      return this.detailsData.desc.replace(/\r\n/g, "<br>");
    },
  },
};
</script>

<style lang="scss" scoped>
.serviceDetails {
  min-height: 100vh;
  background-color: #f4f6fa;
  box-sizing: border-box;
  padding: 31px 0px;
  .page_area_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 13px;
  }
  /deep/.el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .sendPrice_model {
    margin-bottom: 23px;

    /deep/ .el-select {
      width: 100%;
    }
    .upload_img_box {
      /deep/ .el-form-item .el-form-item__content {
        margin-left: 0 !important;
      }
      .upload_img_tags_text {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 10px;
      }
    }
    .text_area_box {
      /deep/ .el-form-item .el-form-item__content {
        margin-left: 0 !important;
      }
      /deep/ .el-textarea textarea {
        height: 106px;
      }
    }
  }
  .bids_desc_top_big_box {
    background: #ffffff;
    border-radius: 8px;
    padding: 25px 19px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .bids_desc_top_info {
      .name_and_price_box {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .jobs_pirce {
          font-size: 24px;
          font-weight: 500;
          color: #ff5100;
        }
        .bids_desc_name {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          margin-right: 20px;
        }
      }

      .bids_area_box {
        display: flex;
        align-items: center;
        gap: 10px 30px;
        flex-wrap: wrap;
        .bids_ep_row_box {
          display: flex;
          align-items: center;
          font-size: 14px;
          .bids_ep_row_title {
            color: #999999;
          }
          .bids_ep_row_text {
            color: #333333;
          }
        }
      }
    }
    .top_right_btn_box {
      flex: none;
      .phone_or_send {
        display: flex;
        align-items: center;
        flex: none;
        gap: 0 20px;
        margin-bottom: 12px;
        .send_price_btn {
          background: #ff5100;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #ff5100;
          width: 108px;
          height: 38px;
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
          flex: none;
        }
      }
      .collect_btn {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        width: fit-content;
        float: right;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }
      }
    }
  }

  .desc_content {
    .area_box {
      position: relative;
    }
    .area-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .company_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .company_info_box {
        display: flex;
        align-items: center;
        .company_img_box {
          width: 58px;
          height: 58px;
          background: #ffffff;
          border-radius: 50%;
          overflow: hidden;
          opacity: 1;
          border: 1px solid #f3f3f3;
          margin-right: 15px;
        }
        .company_name {
          font-size: 18px;
          color: #333;
        }
      }
      .check_company_btn {
        .s_blue_btn {
          padding: 8px 21px;
        }
      }
    }
    .bids_details_box {
      min-height: 612px;
    }
    .desc_tabs {
      margin-bottom: 40px;
    }
    .row_list_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }
    .inner_desc_box {
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .files_list_box {
      .files_item {
        width: 100%;
        height: 700px;
      }
    }
  }

  //认证提醒
  .approve_box {
    width: 596px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #cee3fc 0%, #ffffff 40%, #ffffff 100%);
    border-radius: 20px;
    opacity: 1;
    padding: 40px 48px;
    text-align: center;
    color: #333333;
    .approve_title {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 52px;
    }
    .approve_text {
      font-size: 30px;
      margin-bottom: 88px;
    }
    .approve_btn_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel_btn,
      .comfirm_btn {
        width: 240px;
        height: 68px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        border-radius: 10px;
        border: 2px solid #cecece;
        color: #333;
      }
      .comfirm_btn {
        background: #3f63ff;
        color: #ffffff;
      }
    }
  }
}
</style>
